<!--
 * @Description: 
 * @Author: 琢磨先生
 * @Date: 2022-09-01 06:14:34
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-09-01 07:01:27
-->

<template>
  <el-popover placement="bottom" :width="400">
    <template #reference>
      <el-link :underline="false" type="primary">物流信息</el-link>
    </template>
    <div style="height:400px;">
      <el-scrollbar>
        <div class="waybill" v-for="item in waybill_list" :key="item.id">
          <div class="no">
            <b>{{item.company_name}}、{{item.waybill_no}}</b>
            <el-button v-if="item.boolRefresh"
              type="primary"
              link
              :loading="item.saving"
              @click="onRefresh(item)"
              size="small"
            >更新</el-button>
          </div>
          <el-alert type="info" :closable="false" title>
            <el-timeline v-if="item.track_list.length>0">
              <el-timeline-item
                v-for="(activity, index) in item.track_list"
                :key="index"
                :timestamp="activity.ftime"
              >{{ activity.context }}</el-timeline-item>
            </el-timeline>
            <div v-else>
              <span>暂无物流信息</span>
            </div>
          </el-alert>
        </div>
      </el-scrollbar>
    </div>
  </el-popover>
</template>

<script>
export default {
  data() {
    return {
      waybill_list: [],
    };
  },
  props: ["order"],
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.$http
        .get("seller/v1/mallOrder/waybill?id=" + this.order.id)
        .then((res) => {
          if (res.code == 0) {
            this.waybill_list = res.data;
          }
        });
    },

    /**
     * 刷新物流
     */
    onRefresh(item) {
      item.saving = true;

      this.$http
        .get(
          `seller/v1/mallOrder/track/refresh?code=${item.kd100_code}&nu=${item.waybill_no}`
        )
        .then((res) => {
          if (res.code == 0) {
            item.track_list = res.data;
          }
        })
        .finally(() => {
          item.saving = false;
        });
    },
  },
};
</script>

<style   scoped>
.waybill {
  margin-top: 20px;
}
.waybill:first-child {
  margin-top: 0;
}
</style>